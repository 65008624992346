import { render, staticRenderFns } from "./trev-spin-content.vue?vue&type=template&id=45ca50d3&scoped=true&"
import script from "./trev-spin-content.vue?vue&type=script&lang=js&"
export * from "./trev-spin-content.vue?vue&type=script&lang=js&"
import style0 from "./trev-spin-content.vue?vue&type=style&index=0&id=45ca50d3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ca50d3",
  null
  
)

export default component.exports