<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        <v-icon class="mr-2"> mdi-playlist-play </v-icon>
        Creating Bulk Invoices for
        <span class="success--text">{{ productName }} </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-4" v-if="!isConfirm && !isFinished">
      Are you sure you want to create invoices for
      <span class="success--text">{{ totalCount }}</span> companies?
    </v-card-text>
    <v-card-text v-if="!isConfirm">
      <v-btn color="success" @click="confirm" class="mr-2">
        <v-icon class="mr-2"> mdi-send-outline </v-icon>
        Yes
      </v-btn>
      <v-btn text @click="cancel"> Cancel </v-btn>
    </v-card-text>
    <v-progress-linear
      v-if="isConfirm && !isFinished"
      :value="currentIndex"
    ></v-progress-linear>
    <v-list v-if="isConfirm && !isFinished">
      <v-list-item>
        <v-list-item-subtitle>
          <small>
            Working on Company {{ currentWorkingOnItem.index }} ({{
              currentWorkingOnItem.companyName
            }}) out of {{ totalCount }}
          </small>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item three-line>
        <v-list-item-icon>
          <v-icon> mdi-send-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Creating Invoice
            <small>({{ currentWorkingOnItem.statusText }})</small> ...
          </v-list-item-title>
          <v-list-item-subtitle>
            Sending to Xero
            <v-progress-circular
              v-show="currentWorkingOnItem.isLoadingXero"
              size="15"
              indeterminate
              color="info"
            ></v-progress-circular>
            <v-icon
              class="success--text"
              v-show="currentWorkingOnItem.isFinishedXero"
            >
              mdi-check
            </v-icon>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Creating in Trevor
            <v-progress-circular
              v-show="currentWorkingOnItem.isLoadingTrevor"
              size="15"
              indeterminate
              color="info"
            ></v-progress-circular>
            <v-icon
              class="success--text"
              v-show="currentWorkingOnItem.isFinishedTrevor"
            >
              mdi-check
            </v-icon>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-if="isFinished">
      <v-list-item
        v-for="invoice in finishedInvoicesTrevor"
        :key="invoice.id"
        three-line
      >
        <v-list-item-icon>
          <v-icon> mdi-cash </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ invoice.companyName }}
            <v-btn
              x-small
              color="primary"
              target="_blank"
              :href="
                'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' +
                invoice.xeroInvoiceId
              "
            >
              <v-icon class="mr-2" small> mdi-open-in-new </v-icon>
              Open in Xero
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>
            Invoice Number: {{ invoice.invoiceNumber }}, Reference:
            {{ invoice.reference }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Total (Ex Vat): £{{ totalPriceByInvoice(invoice) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    isLoadingInitial: {
      type: Boolean,
      default: true,
    },
    productType: {
      type: String,
      default: "TBANK", //TBANK or PAYG
    },
    inputData: {
      type: Array,
      default: () => [],
    },
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentIndex() {
      return (this.currentWorkingOnItem.index / this.totalCount) * 100;
    },
    productName() {
      return this.productType == "TBANK" ? "Time Bank" : "Pay As You Go";
    },
    totalCount() {
      if (this.inputData) {
        return this.inputData.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    totalPriceByInvoice(inv) {
      var total = 0;

      for (const lineItem of inv.lineItems) {
        total = total + (lineItem.quantity * lineItem.unitCost);
      }

      return total;
    },
    cancel() {
      this.$emit("updated:isLoadingInitial", false);
      this.$emit("cancelled");
    },
    async confirm() {
      this.isConfirm = true;
      this.$emit("updated:isLoadingInitial", false);
      this.currentWorkingOnItem.index = 0;

      for (const item of this.inputData) {
        this.currentWorkingOnItem.index++;
        this.currentWorkingOnItem.companyName = item.companyName;
        this.currentWorkingOnItem.companyId = item.comapnyId;
        this.currentWorkingOnItem.isLoadingXero = true;
        this.currentWorkingOnItem.isLoadingTrevor = true;
        this.currentWorkingOnItem.isFinishedTrevor = false;
        this.currentWorkingOnItem.isFinishedXero = false;
        this.setStatus("Getting Company");

        //generate the invoice for xero

        //get the company attached
        var company = await this.$courier.Companies.getById(item.companyId);

        this.setStatus("Finding Product");

        var productFound = await this.$courier.Products.getById(item.productId);

        this.setStatus("Getting Account Code");

        var xeroAccountCode = await this.$courier.XeroAccountExternal.getById(
          productFound.defaultXeroAccountCodeId,
          [
            {
              key: "tenantId",
              value: productFound.defaultXeroTenantId,
            },
          ]
        );

        this.setStatus("Getting Xero Links");

        var xeroLinks = await this.$courier.Companies.withId(
          company.id
        ).XeroLinks.getData(1, 25, "");

        var dueDate = new Date();
        dueDate.setMonth(dueDate.getMonth());
        dueDate.setDate(dueDate.getDate() + company.invoicingPeriod);
        dueDate.setYear(dueDate.getFullYear());

        this.setStatus("Generating Invoice");

        var invoice = {
          date: new Date(),
          dueDate: dueDate,
          reference: "Support Overage (" + productFound.skuNumber + ")",
          contactId:
            !!xeroLinks && xeroLinks.payload.length > 0
              ? xeroLinks.payload[0].xeroCustomerId
              : "",
          tenantId: productFound.defaultXeroTenantId,
          lineItems: [
            {
              description:
                "Support Usage From " +
                this.startDate +
                " To " +
                this.endDate +
                " (in hours)",
              quantity:
                Math.round((item.timeToCharge / 60 + Number.EPSILON) * 100) /
                100,
              unitCost:
                productFound.resourceType == "Time Bank"
                  ? productFound.excessPrice
                  : productFound.price,
              accountCode: xeroAccountCode.code,
              accountCodeId: xeroAccountCode.id,
              accountCodeName: xeroAccountCode.name,
            },
          ],
        };

        //send the invoice to xero
        this.setStatus("Sending to Xero");

        var xeroInvoice = await this.$courier.XeroInvoiceExternal.add(
          invoice,
          false,
          [
            {
              key: "tenantId",
              value: invoice.tenantId,
            },
          ]
        );

        this.finishedInvoicesXero.push(xeroInvoice);

        this.currentWorkingOnItem.isLoadingXero = false;
        this.currentWorkingOnItem.isFinishedXero = true;

        this.setStatus("Creating In Trevor");
        var trevorInvoice = {
          reference: invoice.reference,
          date: invoice.date,
          dueDate: invoice.dueDate,
          xeroTenantId: invoice.tenantId,
          xeroContactId: invoice.contactId,
          xeroInvoiceId: xeroInvoice.id,
          xeroInvoiceNumber: xeroInvoice.invoiceNumber,
        };

        var newInvoice = await this.$courier.Companies.withId(
          company.id
        ).Invoices.add(trevorInvoice);

        newInvoice.lineItems = [];

        for (var lineItem of invoice.lineItems) {
          var newLineItem = {
            accountCode: lineItem.accountCode,
            accountCodeName: lineItem.accountCodeName,
            description: lineItem.description,
            quantity: lineItem.quantity,
            unitCost: lineItem.unitCost,
          };

          var generatedLineItem = await this.$courier.Invoices.withId(
            newInvoice.id
          ).LineItems.add(newLineItem);

          newInvoice.lineItems.push(newLineItem);
        }

        newInvoice.companyName = company.name;

        this.finishedInvoicesTrevor.push(newInvoice);

        this.currentWorkingOnItem.isFinishedTrevor = true;
        this.currentWorkingOnItem.isLoadingTrevor = false;
      }

      this.isFinished = true;
      this.setStatus("Finished");
    },
    setStatus(statusText) {
      this.$nextTick(() => {
        this.currentWorkingOnItem.statusText = statusText;
      });
    },
  },
  data() {
    return {
      isFinished: false,
      isConfirm: false,
      finishedInvoicesXero: [],
      finishedInvoicesTrevor: [],
      currentWorkingOnItem: {
        index: 1,
        companyName: "",
        companyId: "",
        isLoadingXero: true,
        isLoadingTrevor: true,
        isFinishedXero: false,
        isFinishedTrevor: false,
      },
    };
  },
};
</script>

<style>
</style>