<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>
          <v-icon class="mr-2"> mdi-store-clock-outline </v-icon>
          Support Time Usages By Company
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" @click="showExportOptions">
          <v-icon class="mr-1"> mdi-database-export-outline </v-icon>
          Export
        </v-btn>
        <v-btn
          color="primary"
          class="mr-2"
          @click="goToPreviousMonth"
          :loading="isLoadingSomething"
        >
          <v-icon class="mr-1"> mdi-arrow-left-thin </v-icon>
          Previous Month</v-btn
        >
        <v-toolbar-items>
          <v-menu
            ref="startDateMenu"
            v-model="startDateMenu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                prepend-icon="mdi-calendar-arrow-left"
                :disabled="isLoadingSomething"
                readonly
                solo
                single-line
                dense
                class="mt-3 mr-2"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="startDateMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.startDateMenu.save(startDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="endDateMenu"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                prepend-icon="mdi-calendar-arrow-left"
                :disabled="isLoadingSomething"
                readonly
                solo
                single-line
                dense
                class="mt-3 mr-2"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="endDateMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endDateMenu.save(endDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-toolbar-items>
        <v-btn
          color="primary"
          @click="goToNextMonth"
          :loading="isLoadingSomething"
        >
          Next Month
          <v-icon class="mr-1"> mdi-arrow-right-thin </v-icon></v-btn
        >
        <v-btn :to="{ name: 'Reports' }"> Return to Reports </v-btn>
      </v-toolbar>
    </v-card>
    <v-card class="mt-4">
      <v-toolbar>
        <v-toolbar-title>
          <v-icon class="mr-2"> mdi-database-clock </v-icon> Company Time Bank
          Usage
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-chip
          v-if="timeBankUsageData.length > 0"
          class="mr-2"
          color="warning"
        >
          <v-icon class="mr-2"> mdi-alarm </v-icon>
          {{ totalChargeTimeBank }} Total To Be Charged</v-chip
        ><v-btn
          color="success"
          small
          :disabled="!timeBankHasCreateInvoice"
          :loading="isLoadingBulkInvoices"
          @click="createBulkInvoices('TBANK')"
        >
          <v-icon class="mr-2">mdi-playlist-play</v-icon>
          Create All Invoices
        </v-btn>
      </v-toolbar>
      <trev-simple-table
        v-if="
          hasLoadedTimeBank &&
          timeBankUsageData.length > 0 &&
          !isLoadingTimeBank
        "
        :hasLoaded="hasLoadedTimeBank"
        :headers="timeBankTableHeaders"
        :isLoading="!hasLoadedTimeBank"
        :items="timeBankUsageData"
        itemKey="id"
        :showSelect="false"
        :showTitleAndSearch="false"
        :showFooter="false"
        :totalRecordCount="999"
        @row-clicked="tableRowClicked"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="
              $store.getters.hasScope('Xero.Create') &&
              item.invoices.length == 0
            "
            color="success"
            small
            class="mr-2"
            @click.stop="showInvoice(item)"
            :disabled="!item.hasXero || item.timeToCharge == 0"
          >
            <v-icon class="mr-2"> mdi-chart-box-plus-outline </v-icon>
            Create Invoice
          </v-btn>
          <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.invoices.length > 0"
                color="success"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
                small
              >
                <v-icon class="mr-2"> mdi-magnify </v-icon>
                See Invoices
              </v-btn>
            </template>
            <v-list v-if="item.invoices && item.invoices.length > 0" dense>
              <v-list-item
                v-if="$store.getters.hasScope('Xero.Create')"
                dense
                color="success"
                @click="showInvoice(item)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-chart-box-plus-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-title> Create Invoice </v-list-item-title>
              </v-list-item>
              <v-list-item
                dense
                v-for="inneritem in item.invoices"
                :key="inneritem.invoiceId"
                :href="
                  'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' +
                  inneritem.invoiceId
                "
                target="blank"
              >
                <v-list-item-icon>
                  <v-icon> mdi-cash </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  inneritem.invoiceNumber
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            color="primary"
            @click.stop="showExportDialog(item.companyId, 'COFF-SUP-TBANK')"
            small
          >
            <v-icon> mdi-database-export-outline </v-icon>
            Export
          </v-btn>
        </template>
        <template v-slot:item.companyName="{ item }">
          <v-icon v-if="!item.isShowing"> mdi-chevron-right </v-icon>
          <v-icon v-else> mdi-chevron-down </v-icon>
          {{ item.companyName }}
          <v-tooltip right>
            <span> Go to dashboard </span>
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :to="{
                  name: 'Company Dashboard',
                  params: {
                    companyId: item.companyId,
                  },
                }"
                color="info"
                target="_blank"
              >
                <v-icon small> mdi-open-in-new </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.category }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.productTimeTotal="{ item }">
          {{ item.productTimeTotal }} Minutes
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted"> </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.timeUsedInProduct="{ item }">
          {{ item.timeUsedInProduct }} Minutes
          <v-btn
            v-if="item.timeUsedInProduct > 0"
            icon
            class="ml-2"
            @click.stop="
              showTimeUsage(
                item.companyId,
                item.companyName,
                'Used Time Bank',
                'COFF-SUP-TBANK',
                'Included'
              )
            "
          >
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.timeUsedInProduct }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.timeToCharge="{ item }">
          {{ item.timeToCharge }} Minutes
          <v-btn
            v-if="item.timeToCharge > 0"
            icon
            class="ml-2"
            @click.stop="
              showTimeUsage(
                item.companyId,
                item.companyName,
                'Used Time Bank',
                'COFF-SUP-TBANK',
                'ChargeOutstanding'
              )
            "
          >
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.timeToCharge }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.rmmCount="{ item }">
          <v-chip v-if="item.rmmCount > 0">
            {{ item.rmmCount }}
          </v-chip>
        </template>
      </trev-simple-table>
      <v-skeleton-loader
        type="table"
        v-show="isLoadingTimeBank"
      ></v-skeleton-loader>
    </v-card>
    <v-card class="mt-4">
      <v-toolbar>
        <v-toolbar-title>
          <v-icon class="mr-2"> mdi-credit-card </v-icon>
          Company Pay As You Go Usage
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-chip v-if="payAsYouGoData.length > 0" class="mr-2" color="warning">
          <v-icon class="mr-2"> mdi-alarm </v-icon>
          {{ totalChargePayAsYouGo }} Total To Be Charged</v-chip
        ><v-btn
          color="success"
          small
          :disabled="!paygHasCreateInvoice"
          @click="createBulkInvoices('PAYG')"
          :loading="isLoadingBulkInvoices"
        >
          <v-icon class="mr-2">mdi-playlist-play</v-icon>
          Create All Invoices
        </v-btn>
      </v-toolbar>
      <trev-simple-table
        v-if="hasLoadedPayg && payAsYouGoData.length > 0 && !isLoadingPAYG"
        :hasLoaded="hasLoadedPayg"
        :headers="[
          { text: 'Company Name', value: 'companyName' },
          { text: 'Outstanding Charges', value: 'timeToCharge' },
          {
            text: 'RMM Count',
            value: 'rmmCount',
          },
          { text: 'Actions', value: 'actions' },
        ]"
        :isLoading="!hasLoadedPayg"
        :items="payAsYouGoData"
        itemKey="id"
        :showSelect="false"
        :showTitleAndSearch="false"
        :showFooter="false"
        :totalRecordCount="999"
        @row-clicked="tableRowClicked"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="
              $store.getters.hasScope('Xero.Create') &&
              item.invoices.length == 0
            "
            color="success"
            small
            class="mr-2"
            @click.stop="showInvoice(item)"
            :disabled="!item.hasXero || item.timeToCharge == 0"
          >
            <v-icon class="mr-2"> mdi-chart-box-plus-outline </v-icon>
            Create Invoice
          </v-btn>
          <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.invoices.length > 0"
                color="success"
                v-on="on"
                v-bind="attrs"
                class="mr-2"
                small
              >
                <v-icon class="mr-2"> mdi-magnify </v-icon>
                See Invoices
              </v-btn>
            </template>
            <v-list v-if="item.invoices && item.invoices.length > 0" dense>
              <v-list-item
                v-if="$store.getters.hasScope('Xero.Create')"
                dense
                color="success"
                @click="showInvoice(item)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-chart-box-plus-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-title> Create Invoice </v-list-item-title>
              </v-list-item>
              <v-list-item
                dense
                v-for="inneritem in item.invoices"
                :key="inneritem.invoiceId"
                :href="
                  'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' +
                  inneritem.invoiceId
                "
                target="blank"
              >
                <v-list-item-icon>
                  <v-icon> mdi-cash </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{
                  inneritem.invoiceNumber
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            color="primary"
            @click.stop="showExportDialog(item.companyId, 'COFF-SUP-PAYG')"
            small
          >
            <v-icon> mdi-database-export-outline </v-icon>
            Export
          </v-btn>
        </template>
        <template v-slot:item.companyName="{ item }">
          <v-icon v-if="!item.isShowing"> mdi-chevron-right </v-icon>
          <v-icon v-else> mdi-chevron-down </v-icon>
          {{ item.companyName }}
          <v-tooltip right>
            <span> Go to dashboard </span>
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :to="{
                  name: 'Company Dashboard',
                  params: {
                    companyId: item.companyId,
                  },
                }"
                color="info"
                target="_blank"
              >
                <v-icon small> mdi-open-in-new </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.category }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.timeToCharge="{ item }">
          {{ item.timeToCharge }} Minutes
          <v-btn
            icon
            class="ml-2"
            @click.stop="
              showTimeUsage(
                item.companyId,
                item.companyName,
                'Charged Pay as you go',
                'COFF-SUP-PAYG',
                'ChargeOutstanding'
              )
            "
          >
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.timeUsedInProduct }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.rmmCount="{ item }">
          <v-chip v-if="item.rmmCount > 0">
            {{ item.rmmCount }}
          </v-chip>
        </template>
      </trev-simple-table>
      <v-skeleton-loader
        type="table"
        v-show="isLoadingPAYG"
      ></v-skeleton-loader>
    </v-card>
    <v-card class="mt-4">
      <v-toolbar>
        <v-toolbar-title>
          <v-icon class="mr-2"> mdi-infinity </v-icon> Company All You Can Eat
          Usage
        </v-toolbar-title>
      </v-toolbar>
      <trev-simple-table
        v-if="hasLoadedAYCE && ayceUsageData.length > 0 && !isLoadingAYCE"
        :hasLoaded="hasLoadedAYCE"
        :headers="ayceTableHeaders"
        :isLoading="!hasLoadedAYCE"
        :items="ayceUsageData"
        itemKey="id"
        :showSelect="false"
        :showTitleAndSearch="false"
        :showFooter="false"
        :totalRecordCount="999"
        @row-clicked="tableRowClicked"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            @click.stop="showExportDialog(item.companyId, 'COFF-SUP-AYCE')"
            small
          >
            <v-icon> mdi-database-export-outline </v-icon>
            Export
          </v-btn>
        </template>
        <template v-slot:item.companyName="{ item }">
          <v-icon v-if="!item.isShowing"> mdi-chevron-right </v-icon>
          <v-icon v-else> mdi-chevron-down </v-icon>
          {{ item.companyName }}
          <v-tooltip right>
            <span> Go to dashboard </span>
            <template #activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                target="_blank"
                :to="{
                  name: 'Company Dashboard',
                  params: {
                    companyId: item.companyId,
                  },
                }"
                color="info"
              >
                <v-icon small> mdi-open-in-new </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.category }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.timeUsedInProduct="{ item }">
          {{ item.timeUsedInProduct }} Minutes
          <v-expand-transition>
            <div v-show="item.isShowing">
              <div v-for="(usage, index) in item.categoryUsages" :key="index">
                <span class="ml-4 text-muted">
                  {{ usage.timeUsedInProduct }}
                </span>
              </div>
            </div>
          </v-expand-transition>
        </template>
        <template v-slot:item.rmmCount="{ item }">
          <v-chip v-if="item.rmmCount > 0">
            {{ item.rmmCount }}
          </v-chip>
        </template>
      </trev-simple-table>
      <v-skeleton-loader
        type="table"
        v-show="isLoadingAYCE"
      ></v-skeleton-loader>
    </v-card>
    <v-dialog v-model="showTimeUsageDetail" max-width="90vh">
      <v-card>
        <v-card-title>
          <h4>
            Showing
            <span class="green--text mr-2 ml-2"
              >{{ timeUsageDetails.showType }} Minutes</span
            >
            for
            <span class="info--text ml-2 mr-2">{{
              timeUsageDetails.companyName
            }}</span>
          </h4>
        </v-card-title>
        <v-card-subtitle>
          Product: {{ timeUsageDetails.productSku }}
        </v-card-subtitle>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                placeholder="Search..."
                append-icon="mdi-magnify"
                v-model="timeUsageDetails.search"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            two-line
            v-for="(ticket, index) in timeUsageDetails.tickets"
            :key="index"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip
                    class="mr-2"
                    :href="ticket.ticketLink"
                    target="_blank"
                  >
                    #{{ ticket.ticketNumber }}
                  </v-chip>
                  <v-chip color="green">
                    {{ ticket.category }}
                  </v-chip>
                  {{ ticket.subject }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ new Date(ticket.dateAdded).toISOString() }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(timeEntry, index) in ticket.timeEntries"
              :key="index"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span> {{ timeEntry.addedBy }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip>
                    Added On {{ timeEntry.timeExecuted.substring() }}
                  </v-chip>
                  <v-chip color="success" class="float-right ml-4">
                    {{ timeEntry.totalInMinutes }} Minutes Total
                  </v-chip>
                  <v-chip color="info" class="float-right ml-4">
                    {{ timeEntry.minutesAddedToProduct }} Minutes Included
                  </v-chip>
                  <v-chip color="warning" class="float-right ml-4">
                    {{ timeEntry.minutesToChargeFor }} Minutes To Be Charged
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn
                  class="float-left"
                  :disabled="timeUsageDetails.currentPage <= 1"
                  @click="timeUsageDetails.currentPage--"
                >
                  Previous
                </v-btn>
                <v-btn
                  class="float-right"
                  :disabled="
                    timeUsageDetails.tickets.length < timeUsageDetails.pageSize
                  "
                  @click="timeUsageDetails.currentPage++"
                >
                  Next
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <trev-ticket-pdf-export-dialog
      :productSku="exportSku"
      :companyId="exportCompanyId"
      :isShow.sync="isShowingExport"
      :startDate="startDateAsDate"
      :endDate="endDateAsDate"
      :byTime="true"
    >
    </trev-ticket-pdf-export-dialog>
    <v-dialog
      v-model="isShowingInvoice"
      max-width="120vh"
      @click:outside="cancelInvoice"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Create Invoice </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-2"
            v-if="$store.getters.hasScope('ChargeItem.Create')"
          >
            <v-icon class="mr-2"> mdi-currency-gbp </v-icon>
            Create as Charge Item
          </v-btn>
          <v-btn
            v-if="
              !isLoadingInvoice &&
              !isSuccessInvoice &&
              $store.getters.hasScope('Xero.Create')
            "
            :loading="isLoadingSentToInvoice"
            color="primary"
            @click="sendInvoiceToXero"
          >
            <v-icon class="mr-2"> mdi-email-fast-outline </v-icon>
            Send to Xero
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-4" v-if="isLoadingInvoice && !isSuccessInvoice">
            <v-col class="text-center my-10">
              <v-progress-circular
                class="py-10"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <trev-invoice-view
            ref="invoiceView"
            :canEdit="false"
            :companyId="loadedInvoiceCompanyId"
            :invoice.sync="loadedInvoice"
            :showFormattedDates="true"
            v-else-if="!isLoadingInvoice && !isSuccessInvoice"
          ></trev-invoice-view>
          <v-row class="my-4" v-else-if="isSuccessInvoice">
            <v-col class="text-center my-10">
              {{ invoiceDialogMessage }}
              <v-icon class="success--text" large> mdi-check </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowingBulkInvoicesDialog" max-width="80vh">
      <support-report-bulk-invoice
        :endDate="endDate"
        :startDate="startDate"
        @cancelled="cancelBulkInvoice"
        :isLoadingInitial.sync="isLoadingBulkInvoices"
        :inputData="bulkInvoiceInputData"
        :productType="selectedBulkInvoiceProduct"
      ></support-report-bulk-invoice>
    </v-dialog>
  </div>
</template>

<script>
import * as prodUtils from "../../../../utilities/Object Utilities/Products";
import trevSimpleTable from "../../../../components/tables/trev-simple-table.vue";
import * as utils from "../../../../utilities/GeneralUtilities";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import TrevSpinContent from "../../../../components/ui/trev-spin-content.vue";
import TrevTicketPdfExportDialog from "../../../../components/reports/export/trev-ticket-pdf-export-dialog.vue";
import TrevInvoiceView from "../../../../components/invoices/trev-invoice-view.vue";
import * as datefns from "date-fns";
import SupportReportBulkInvoice from "./Components/SupportReportBulkInvoice.vue";

export default {
  components: {
    trevSimpleTable,
    TrevSpinContent,
    TrevTicketPdfExportDialog,
    TrevInvoiceView,
    SupportReportBulkInvoice,
  },
  watch: {
    startDate() {
      if (Date.parse(this.startDate) != NaN && !this.isLoadingSomething) {
        this.getAllUsages();
      }
    },
    endDate() {
      if (Date.parse(this.endDate) != NaN && !this.isLoadingSomething) {
        this.getAllUsages();
      }
    },
    isShowingExport(newValue, oldValue) {
      if (!newValue) {
        this.exportCompanyId = "";
        this.exportSku = "";
      }
    },
  },
  computed: {
    paygHasCreateInvoice() {
      return this.payAsYouGoData.some((x) => x.hasXero && x.timeToCharge > 0);
    },
    timeBankHasCreateInvoice() {
      return this.timeBankUsageData.some(
        (x) => x.hasXero && x.timeToCharge > 0
      );
    },
    isLoadingSomething() {
      return this.isLoadingAYCE || this.isLoadingTimeBank || this.isLoadingPAYG;
    },
    startDateAsDate() {
      return new Date(this.startDate);
    },
    endDateAsDate() {
      var rObj = new Date(this.endDate);
      rObj.setHours(23, 59, 59, 999);

      return rObj;
    },
    timeUsageDetailsPageLength() {
      return Math.round(
        this.timeUsageDetails.tickets.length / this.timeUsageDetails.pageSize
      );
    },
    totalChargeTimeBank() {
      var hoursAndMinutes = utils.convertMinutesToHoursAndMinutes(
        this.timeBankUsageData.reduce(
          (partialSum, a) => partialSum + a.timeToCharge,
          0
        )
      );

      return (
        hoursAndMinutes.hours +
        " Hours and " +
        hoursAndMinutes.minutes +
        " Minutes"
      );
    },
    totalChargeFiveHours() {
      var hoursAndMinutes = utils.convertMinutesToHoursAndMinutes(
        this.payAsYouGoFiveHoursData.reduce(
          (partialSum, a) => partialSum + a.timeToCharge,
          0
        )
      );

      return (
        hoursAndMinutes.hours +
        " Hours and " +
        hoursAndMinutes.minutes +
        " Minutes"
      );
    },
    totalChargePayAsYouGo() {
      var hoursAndMinutes = utils.convertMinutesToHoursAndMinutes(
        this.payAsYouGoData.reduce(
          (partialSum, a) => partialSum + a.timeToCharge,
          0
        )
      );

      return (
        hoursAndMinutes.hours +
        " Hours and " +
        hoursAndMinutes.minutes +
        " Minutes"
      );
    },
  },
  created() {
    //set defefault dates
    var startAndEndOfCurrentMonth = utils.getMinMaxDatesByDate(new Date());

    this.startDate =
      startAndEndOfCurrentMonth.startDate.getFullYear() +
      "-" +
      (startAndEndOfCurrentMonth.startDate.getMonth() + 1) +
      "-" +
      startAndEndOfCurrentMonth.startDate.getDate();
    this.endDate =
      startAndEndOfCurrentMonth.endDate.getFullYear() +
      "-" +
      (startAndEndOfCurrentMonth.endDate.getMonth() + 1) +
      "-" +
      startAndEndOfCurrentMonth.endDate.getDate();

    this.getAllUsages();
  },
  methods: {
    async createAsChargeItem(supportItem) {
      var company = await this.$courier.Companies.getById(
        supportItem.companyId
      );
      var dueDate = new Date();
      dueDate.setDate(dueDate.getDate() + company.invoicingPeriod);

      var unitCost = 0;

      if (foundProduct.resourceType == "Time Bank") {
        unitCost = foundProduct.excessPrice;
      } else {
        unitCost = foundProduct.price;
      }

      var foundProduct = await this.$courier.Products.getById(
        supportItem.productId
      );

      await this.$courier.Companies.withId(
        supportItem.companyId
      ).ChargeItems.add({
        name: "Support Overage",
        description:
          "Support Usage From " + this.startDate + " To " + this.endDate,
        startDate: new Date(),
        endDate: dueDate,
        quantity: supportItem.timeToCharge / 60,
        costPrice: unitCost,
        retailPrice: unitCost,
        sku: foundProduct.skuNumber,
      });

      this.invoiceDialogMessage = "Created charge item";
    },
    async sendInvoiceToXero() {
      this.isLoadingSentToInvoice = true;

      //remove the convenience props from invoice line items

      var generatedInvoice = await this.$courier.XeroInvoiceExternal.add(
        this.loadedInvoice,
        false,
        [{ key: "tenantId", value: this.loadedInvoice.tenantId }]
      );

      this.isLoadingSentToInvoice = false;
      this.isSuccessInvoice = true;
      this.invoiceDialogMessage = "Successfully sent invoice to xero";

      //add the invoice details onto the time entries

      var allTasks = [];

      for (const timeEntryId of this.currentTimeEntryIdsForInvoice) {
        var task = this.$courier.ZohoTimeEntries.AddInvoiceDetails(
          timeEntryId,
          generatedInvoice.id,
          generatedInvoice.invoiceNumber,
          generatedInvoice.reference
        ).then(() => {
          //idk do something here?
        });

        allTasks.push(task);
      }

      Promise.allSettled(allTasks).then(() => {
        this.getAllUsages();
      });

      //add it into the log

      //generate the add object

      var invoiceLogItem = {
        reference: generatedInvoice.reference,
        date: this.loadedInvoice.date,
        dueDate: this.loadedInvoice.dueDate,
        xeroTenantId: this.loadedInvoice.tenantId,
        xeroContactId: generatedInvoice.contactId,
        xeroInvoiceId: generatedInvoice.id,
        xeroInvoiceNumber: generatedInvoice.invoiceNumber,
      };
      this.$courier.Companies.withId(this.loadedInvoiceCompanyId)
        .Invoices.add(invoiceLogItem)
        .then((newInvoice) => {
          //generate the line items

          for (var lineItem of this.loadedInvoice.lineItems) {
            var newLineItem = {
              accountCode: lineItem.accountCode,
              accountCodeName: lineItem.accountCodeName,
              description: lineItem.description,
              quantity: lineItem.quantity,
              unitCost: lineItem.unitCost,
            };

            this.$courier.Invoices.withId(newInvoice.id)
              .LineItems.add(newLineItem)
              .then((newLineItem) => {
                //idk what to do here with the ne wline item
              });
          }
        });
    },
    async showInvoice(supportItem) {
      this.isLoadingInvoice = true;
      this.currentTimeEntryIdsForInvoice = supportItem.timeEntryIds;
      this.selectedSupportItem = supportItem;
      this.selectedProductSku = supportItem.productSku;
      this.loadedInvoiceCompanyId = supportItem.companyId;
      //get the product price

      var foundProduct = await this.$courier.Products.getById(
        supportItem.productId
      );
      var priceRuleResp = await this.$courier.Products.withId(
        supportItem.productId
      ).PriceRules.getData(1, 999, "");
      foundProduct.priceRules = priceRuleResp.payload;

      var date = new Date();

      var companyAttached = await this.$courier.Companies.getById(
        supportItem.companyId
      );

      this.loadedInvoice.date = new Date();
      date.setMonth(date.getMonth());
      date.setDate(date.getDate() + companyAttached.invoicingPeriod);
      date.setYear(date.getFullYear());
      this.loadedInvoice.dueDate = date;
      this.loadedInvoice.reference =
        "Support Overage (" + foundProduct.skuNumber + ")";

      var unitCost = 0;

      if (foundProduct.resourceType == "Time Bank") {
        unitCost = foundProduct.excessPrice;
      } else {
        unitCost = foundProduct.price;
      }

      //get the default account code

      var accCode = await this.$courier.XeroAccountExternal.getById(
        foundProduct.defaultXeroAccountCodeId,
        [
          {
            key: "tenantId",
            value: foundProduct.defaultXeroTenantId,
          },
        ]
      );

      this.loadedInvoice.lineItems = [
        {
          description:
            "Support Usage From " +
            this.startDate +
            " To " +
            this.endDate +
            " (in hours)",
          quantity:
            Math.round((supportItem.timeToCharge / 60 + Number.EPSILON) * 100) /
            100,
          unitCost: unitCost,
          accountCode: accCode.code,
          accountCodeId: accCode.id,
          accountCodeName: accCode.name,
        },
      ];
      this.isLoadingInvoice = false;
      this.$nextTick(() => {
        this.isShowingInvoice = true;
        this.$refs.invoiceView.getData();
      });
    },
    cancelInvoice() {
      this.loadedInvoice = {
        date: "",
        dueDate: "",
        lineItems: [],
        reference: "",
        contactId: "",
      };
      this.isLoadingInvoice = true;
      this.isShowingInvoice = false;
      this.loadedInvoiceCompanyId = "";
      this.isSuccessInvoice = false;
      this.currentTimeEntryIdsForInvoice = [];
    },
    showExportDialog(companyId, exportSku) {
      this.isShowingExport = true;
      this.exportCompanyId = companyId;
      this.exportSku = exportSku;
    },
    tableRowClicked(row) {
      row.isShowing = !row.isShowing;
    },
    goToNextMonth() {
      var start = datefns.addMonths(new Date(this.startDate), 1);

      var mmm = utils.getMinMaxDatesByDate(start);

      this.startDate =
        mmm.startDate.getFullYear() +
        "-" +
        (mmm.startDate.getMonth() + 1) +
        "-" +
        mmm.startDate.getDate();
      this.endDate =
        mmm.endDate.getFullYear() +
        "-" +
        (mmm.endDate.getMonth() + 1) +
        "-" +
        mmm.endDate.getDate();
    },
    goToPreviousMonth() {
      var start = datefns.subMonths(new Date(this.startDate), 1);

      var mmm = utils.getMinMaxDatesByDate(start);

      this.startDate =
        mmm.startDate.getFullYear() +
        "-" +
        (mmm.startDate.getMonth() + 1) +
        "-" +
        mmm.startDate.getDate();
      this.endDate =
        mmm.endDate.getFullYear() +
        "-" +
        (mmm.endDate.getMonth() + 1) +
        "-" +
        mmm.endDate.getDate();
    },
    showExportOptions() {
      this.showingExportOptions = true;
    },
    showTimeUsage(companyId, companyName, type, sku, getType) {
      this.timeUsageDetails.companyId = companyId;
      this.timeUsageDetails.productSku = sku;
      this.timeUsageDetails.showType = type;
      this.timeUsageDetails.companyName = companyName;
      this.timeUsageDetails.internalGetType = getType;
      this.getTimeUsageTickets().then(() => {
        this.showTimeUsageDetail = true;
      });
    },
    async getTimeUsageTickets() {
      return new Promise((resolve, reject) => {
        this.$courier.Zoho.GetTicketsByProductSkuAndTimeType(
          this.timeUsageDetails.companyId,
          this.timeUsageDetails.productSku,
          this.timeUsageDetails.internalGetType,
          new Date(this.startDate),
          new Date(this.endDate),
          this.timeUsageDetails.currentPage,
          this.timeUsageDetails.pageSize,
          this.timeUsageDetails.search
        )
          .then((x) => {
            this.timeUsageDetails.tickets = x;
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
    getAllUsages() {
      this.getTimeBankUsages();
      this.getPayAsYouGoUsages();
      this.getAYCEUsages();
      //this.getPayAsYouGoFiveHoursUsages();
    },
    async getTimeBankUsages() {
      var sDate = new Date(this.startDate);
      var eDate = new Date(this.endDate);
      eDate.setHours(23, 59, 59, 999);

      this.hasLoadedTimeBank = false;
      this.isLoadingTimeBank = true;

      this.$courier.Zoho.GetTimeBankUsageReport(
        sDate.toLocaleString("en-US"),
        eDate.toLocaleString("en-US")
      ).then((timeBankCompanies) => {
        var allPromises = [];
        for (const comp of timeBankCompanies) {
          comp.isShowing = false;
          comp.hasXero = false;
        }

        this.timeBankUsageData = timeBankCompanies;
        this.hasLoadedTimeBank = true;

        if (this.$store.getters.hasScope("Xero.Create")) {
          for (const comp of this.timeBankUsageData) {
            //get the xero link count if more than 0 then yes

            var p = this.$courier.Companies.withId(comp.companyId)
              .XeroLinks.getData(1, 0, "")
              .then((data) => {
                comp.hasXero = data.totalCount > 0;
              });

            allPromises.push(p);
          }

          Promise.allSettled(allPromises).then(() => {
            this.isLoadingTimeBank = false;
          });
        } else {
          this.isLoadingTimeBank = false;
        }
      });
    },
    async getAYCEUsages() {
      this.hasLoadedAYCE = false;
      this.isLoadingAYCE = true;
      var sDate = new Date(this.startDate);
      var eDate = new Date(this.endDate);
      eDate.setHours(23, 59, 59, 999);

      this.$courier.Zoho.GetAllYouCanEatReport(
        sDate.toLocaleString("en-US"),
        eDate.toLocaleString("en-US")
      ).then((ayceCompanies) => {
        for (var comp of ayceCompanies) {
          comp.isShowing = false;
        }

        this.ayceUsageData = ayceCompanies;
        this.hasLoadedAYCE = true;
        this.isLoadingAYCE = false;
      });
    },
    async getPayAsYouGoUsages() {
      this.hasLoadedPayg = false;
      this.isLoadingPAYG = true;
      var sDate = new Date(this.startDate);
      var eDate = new Date(this.endDate);
      eDate.setHours(23, 59, 59, 999);

      this.$courier.Zoho.GetPayAsYouGoUsageReport(
        sDate.toLocaleString("en-US"),
        eDate.toLocaleString("en-US")
      ).then((paygCompanies) => {
        for (var comp of paygCompanies) {
          comp.isShowing = false;
          comp.hasXero = false;
        }

        this.payAsYouGoData = paygCompanies;

        this.hasLoadedPayg = true;

        if (this.$store.getters.hasScope("Xero.Create")) {
          var allPromises = [];

          for (const comp of this.payAsYouGoData) {
            //get the xero link count if more than 0 then yes

            var prom = this.$courier.Companies.withId(comp.companyId)
              .XeroLinks.getData(1, 0, "")
              .then((data) => {
                comp.hasXero = data.totalCount > 0;
              });

            allPromises.push(prom);
          }

          Promise.allSettled(allPromises).then(() => {
            this.isLoadingPAYG = false;
          })
        } else {
          this.isLoadingPAYG = false;
        }
      });
    },
    getPayAsYouGoFiveHoursUsages() {
      this.hasLoadedPaygFiveHour = false;
      this.$courier.Zoho.GetPayAsYouGo5HoursUsageReport(
        new Date(this.startDate),
        new Date(this.endDate)
      ).then((paygCompanies) => {
        this.hasLoadedPaygFiveHour = true;
        this.payAsYouGoFiveHoursData = paygCompanies;
      });
    },
    async createBulkInvoices(productType) {
      this.selectedBulkInvoiceProduct = productType;

      if (productType == "PAYG") {
        this.bulkInvoiceInputData = this.payAsYouGoData;
      } else if (productType == "TBANK") {
        this.bulkInvoiceInputData = this.timeBankUsageData;
      }

      this.isShowingBulkInvoicesDialog = true;
    },
    cancelBulkInvoice() {
      this.selectedBulkInvoiceProduct = "";
      this.isShowingBulkInvoicesDialog = false;
      this.bulkInvoiceInputData = [];
    },
  },
  data() {
    return {
      selectedSupportItem: {},
      selectedProductSku: "",
      bulkInvoiceInputData: [],
      selectedBulkInvoiceProduct: "",
      isLoadingBulkInvoices: false,
      isShowingBulkInvoicesDialog: false,
      exportSku: "",
      isLoadingAYCE: true,
      isLoadingTimeBank: true,
      isLoadingPAYG: true,
      invoiceDialogMessage: "",
      isLoadingSentToInvoice: false,
      isSuccessInvoice: false,
      loadedInvoiceCompanyId: "",
      loadedInvoice: {
        date: "",
        dueDate: "",
        lineItems: [],
        reference: "",
        contactId: "",
        tenantId: "",
        accountCode: "",
      },
      isLoadingInvoice: false,
      isShowingInvoice: false,
      exportCompanyId: "",
      isShowingExport: false,
      showingExportOptions: false,
      exportOptions: {
        fileName: "",
      },
      showTimeUsageDetail: false,
      timeUsageDetails: {
        search: "",
        companyId: "",
        productSku: "",
        showType: "",
        companyName: "",
        internalGetType: "",
        currentPage: 1,
        pageSize: 5,
        tickets: [],
      },
      timeBankTableHeaders: [
        {
          text: "Company Name",
          value: "companyName",
        },
        {
          text: "Time Bank",
          value: "productTimeTotal",
        },
        {
          text: "Time Used In Time Bank",
          value: "timeUsedInProduct",
        },
        {
          text: "Outstanding Time Bank Charges",
          value: "timeToCharge",
        },
        {
          text: "RMM Count",
          value: "rmmCount",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      ayceTableHeaders: [
        {
          text: "Company Name",
          value: "companyName",
        },
        {
          text: "Minutes Used",
          value: "timeUsedInProduct",
        },
        {
          text: "RMM Count",
          value: "rmmCount",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      paygFiveHoursTableHeaders: [
        {
          text: "Company Name",
          value: "companyName",
        },
        {
          text: "Payg Included",
          value: "productTimeTotal",
        },
        {
          text: "Time Used From Included",
          value: "timeUsedInProduct",
        },
        {
          text: "Outstanding Time Bank Charges",
          value: "timeToCharge",
        },
        {
          text: "RMM Count",
          value: "rmmCount",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      timeBankUsageData: [],
      ayceUsageData: [],
      payAsYouGoData: [],
      payAsYouGoFiveHoursData: [],
      startDateMenu: false,
      endDateMenu: false,
      startDate: "",
      endDate: "",
      hasLoadedTimeBank: false,
      hasLoadedPaygFiveHour: false,
      hasLoadedPayg: false,
      hasLoadedAYCE: false,
    };
  },
};
</script>

<style></style>
