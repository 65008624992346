<template>
  <span class="toggleUpDown" :class="{ 'rotate' : isShow }">
    <slot> </slot>
  </span>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.toggleUpDown {
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(90deg);
}
</style>